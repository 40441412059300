function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 15,
              "_name": "J and transform(A) and MES * score_multiply_15"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 10,
              "_name": "J and MES * score_multiply_10"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.second_char_transform*"
                          ]
                        }
                      },
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.third_char_transform*"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_plus_s"
                    ]
                  }
                }
              ],
              "boost": 7,
              "_name": "J and transform(AM) and (ESS or ESS) * score_multiply_7"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.second_char_transform*"
                          ]
                        }
                      },
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.third_char_transform*"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 6,
              "_name": "J and transform(AM) and ES? * score_multiply_6"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 5,
              "_name": "transform(J) and transform(A) and MES * score_multiply_5"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.third_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 4,
              "_name": "transform(J) and transform(A) and transform(M) and ES? * score_multiply_4"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.third_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 3,
              "_name": "transform(J) and transform(A) and transform(M) and ?ES * score_multiply_3"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_3_chars_ngram": searchTerm
                  }
                }
              ],
              "boost": 2,
              "_name": "JAM * score_multiply_2"
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true
  };
}
export default NewQuery;
