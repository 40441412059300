function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "match": {
                    "label.first_3_chars_drop_first_keep_rest": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 8,
              "_name": "M and ATT and HEW * score_multiply_8"
            }
          },        
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.second_char_transform*"
                          ]
                        }
                      },
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.third_char_transform*"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 10,
              "_name": "M and transform (AT) and HEW * score_multiply_10"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "bool": {
                    "must": [
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.third_char_transform*"
                          ]
                        }
                      },
                      {
                        "multi_match": {
                          "query": searchTerm,
                          "fields": [
                            "label.fourth_char_transform*"
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 6,
              "_name": "M and transform (TT) and HEW * score_multiply_6"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "match": {
                    "label.last_3_chars": searchTerm
                  }
                }
              ],
              "boost": 5,
              "_name": "M and HEW * score_multiply_5"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_3_chars_ngram": searchTerm
                  }
                }
              ],
              "boost": 2,
              "_name": "MAT * score_multiply_2"
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true
  };
}
export default NewQuery;
