function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "match": {
              "label.ngram": {
                "query": searchTerm,
                "boost": 10,
                "_name": "exact match SAM * score_multiply_10"
              }
            }
          },
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_plus_s"
                    ]
                  }
                }
              ],
              "boost": 10,
              "_name": "S and (AMM or AMS) * score_multiply_10"
            }
          },
          {
            "multi_match": {
              "query": searchTerm,
              "fields": [
                "label.ngram_transform_3chars_*"
              ],
              "boost": 6,
              "_name": "transform(SAM) * score_multiply_6"
            }
          },          
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 4,
              "_name": "S and AM? * score_multiply_4"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_triple_last",
                      "label.last_3_chars_double_last_plus_s"
                    ]
                  }
                }
              ],
              "boost": 3,
              "_name": "transform(S) and transform(A) (MMM or MMS) * score_multiply_3"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_plus_s",
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 2,
              "_name": "transform(S) and (AMM or AMS or AM?) * score_multiply_2"
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true
  };
}
export default NewQuery;
