function NewQuery(searchTerm, currentPage, labelsPerPage) {
  return {
    "query": {
      "bool": {
        "should": [
          {
            "bool": {
              "must": [
                {
                  "match": {
                    "label.first_char": searchTerm
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 10,
              "_name": "J and OHN * score_multiply_10"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars"
                    ]
                  }
                }
              ],
              "boost": 8,
              "_name": "transform(J) and OHN * score_multiply_8"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.ngram_transform1",
                      "label.ngram_transform2"
                    ]
                  }
                }
              ],
              "boost": 7,
              "_name": "transform(JOHN) * score_multiply_7"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_plus_s"
                    ]
                  }
                }
              ],
              "boost": 7,
              "_name": "J and transform(O) and (HNN or HNS) * score_multiply_7"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_penultimate"
                    ]
                  }
                }
              ],
              "boost": 6,
              "_name": "J and transform(O) and HHN * score_multiply_6"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_3_chars_double_last",
                      "label.last_3_chars_plus_s",
                      "label.last_3_chars_double_penultimate"
                    ]
                  }
                }
              ],
              "boost": 5,
              "_name": "transform(J) and transform(O) and (HNN or HNS or HHN) * score_multiply_5"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 4,
              "_name": "J and transform(O) and HN? * score_multiply_4"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.second_char_transform*"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars"
                    ]
                  }
                }
              ],
              "boost": 3,
              "_name": "J and transform(O) and ?HN * score_multiply_3"
            }
          },
          {
            "bool": {
              "must": [
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.first_char"
                    ]
                  }
                },
                {
                  "multi_match": {
                    "query": searchTerm,
                    "fields": [
                      "label.last_2_chars_drop_last_keep_first_two"
                    ]
                  }
                }
              ],
              "boost": 2,
              "_name": "J and HN? * score_multiply_2"
            }
          }
        ]
      }
    },
    "size": labelsPerPage,
    "from": (currentPage - 1) * labelsPerPage,
    "track_total_hits": true
  };
}
export default NewQuery;
